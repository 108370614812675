export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/blog": [3],
		"/cabinet-demo": [4],
		"/csview": [5],
		"/demo": [6],
		"/lock-network-configuration": [7],
		"/msa": [8],
		"/resources": [9],
		"/resources/ventaire-network-configuration": [10],
		"/solutions/endoscopy": [11],
		"/solutions/logbook": [12],
		"/solutions/security": [13]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';